import React from 'react'
import classNames from 'classnames'

import ImageAsset from '@components/image_asset'
import Markdown from '@components/markdown'

import styles from '@components/blocks/text_and_image_block.module.scss'

const LinkRenderer = ({ href, children }) => {
  const linkAttributes = {}

  if (/^http(s)?:\/\//.test(href)) {
    linkAttributes.target = '_blank'
    linkAttributes.rel = 'noopener noreferrer'
  }

  return (
    <a {...linkAttributes} href={href}>
      {children}
    </a>
  )
}

const TextAndImageBlock = React.forwardRef(
  (
    { fields: { title, description, image, imagePosition, backgroundColor, layout, spacing } },
    _
  ) => {
    const imagePositionClass = (position => {
      switch (position) {
        case 'Right':
          return styles['text_and_image_block--image_right']
        case 'Center':
          return styles['text_and_image_block--image_center']
        case 'Left':
        default:
          return styles['text_and_image_block--image_left']
      }
    })(imagePosition)

    const backgroundColorClass = (backgroundColor => {
      switch (backgroundColor) {
        case 'Light Blue':
          return styles['text_and_image_block--light_blue']
        case 'Tan':
          return styles['text_and_image_block--tan']
        default:
          return null
      }
    })(backgroundColor)

    const stackedLayout = 'Stacked'
    const layoutClass = (layout => {
      switch (layout) {
        case 'Wide Text':
          return styles['text_and_image_block--wide_text']
        case 'Wide Image':
          return styles['text_and_image_block--wide_image']
        case stackedLayout:
          return styles['text_and_image_block--stacked']
        default:
          return null
      }
    })(layout)

    const spacingClass = (spacing => {
      switch (spacing) {
        case 'Extra Top and Bottom':
          return styles['text_and_image_block--extra-top-and-bottom-spacing']
        case 'Extra Top':
          return styles['text_and_image_block--extra-top-spacing']
        case 'Extra Bottom':
          return styles['text_and_image_block--extra-bottom-spacing']
        default:
          return null
      }
    })(spacing)

    const imageWidth = layout === stackedLayout ? undefined : '330'

    return (
      <div
        className={classNames(
          styles.text_and_image_block,
          imagePositionClass,
          backgroundColorClass,
          layoutClass,
          spacingClass
        )}
      >
        <div className={styles.text_and_image_block__image}>
          <ImageAsset asset={image} width={imageWidth} />
        </div>
        <div className={styles.text_and_image_block__text}>
          <h2 className={styles.text_and_image_block__title}>{title}</h2>
          <Markdown
            className={styles.text_and_image_block__description}
            components={{ link: LinkRenderer }}
          >
            {description}
          </Markdown>
        </div>
      </div>
    )
  }
)

export default TextAndImageBlock
